import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/react";
import Main from "./pages/Main/Main";
import Staking from "./pages/Staking/Staking";

const projectId = "1c49db3470dcb56a6e858311a77bb3bf";

const mainnet = {
  chainId: 1,
  name: "Ethereum",
  currency: "ETH",
  explorerUrl: "https://etherscan.io/",
  rpcUrl: "https://cloudflare-eth.com",
};

const metadata = {
  name: "ZuckPepe",
  description: "ZuckPepe",
  url: "https://zuckpepe.com", // origin must match your domain & subdomain
  icons: [""],
};

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [mainnet],
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
});

const router = createBrowserRouter([
  { path: "/", element: <Main /> },
  { path: "/staking", element: <Staking /> },
]);
function App() {
  return <RouterProvider router={router} />;
}

export default App;
