import React from "react";
import StakingHeader from "../../components/StakingHeader/StakingHeader";
import StakingHome from "../../components/StakingHome/StakingHome";
import { WalletProvider } from "./WalletContext";
import "./Staking.css";

export default function Staking() {
  return (
    <WalletProvider>
      <div className="Staking">
        <StakingHeader />
        <StakingHome />
      </div>
    </WalletProvider>
  );
}
