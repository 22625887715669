import React, { useEffect, useState, useCallback } from "react";
import masterchefABI from "../../config/abi/MasterChef.json";
import ZuckPepeABI from "../../config/abi/ZuckPepe.json";
import ZuckABI from "../../config/abi/Zuck.json";
import SmartChefInitializableABI from "../../config/abi/SmartChefInitializable.json";
import { getMulticallContract } from "../../config/multicall";
import { ethers, Contract } from "ethers";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers5/react";
import contracts from "../../config/contract/contracts";
import { Interface } from "@ethersproject/abi";
import Footer from "../Footer/Footer";
import ConnectWallet from "../ConnectWallet/ConnectWallet";
import {
  Modal,
  Button,
  TextField,
  Skeleton,
  CircularProgress,
} from "@mui/material";
import "./StakingHome.css";

function getProvider(walletProvider: any) {
  return new ethers.providers.Web3Provider(walletProvider);
}

export default function StakingHome() {
  const { address, isConnected } = useWeb3ModalAccount();
  const [isloading, setIsLoading] = useState(false);
  const [pendingZuckPepe, setPendingZuckPepe] = useState(0);
  const [pendingZuck, setPendingZuck] = useState(0);
  const [stakedAmountOfZuckPepe, setStakedAmountOfZuckPepe] = useState(0);
  const [stakedAmountOfZuck, setStakedAmountOfZuck] = useState(0);
  const [zuckPepeBalance, setZuckPepeBalance] = useState(0);
  const [zuckBalance, setZuckBalance] = useState(0);
  const [zuckPepeBalance_masterchef, setZuckPepeBalanceOfMasterChef] =
    useState(0);
  const [zuckPepeBalance_smartchef, setZuckPepeBalanceOfSmartChef] =
    useState(0);
  const [stakingAmount_1, setStakingAmount1] = useState("");
  const [stakingAmount_2, setStakingAmount2] = useState("");
  const [unstakingAmount_1, setUnstakingAmount1] = useState("");
  const [unstakingAmount_2, setUnstakingAmount2] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUnstakeModalOpen, setIsUnstakeModalOpen] = useState(false);
  const [selectedPool, setSelectedPool] = useState("");
  const [selectedPoolforUnstake, setSelectedPoolForUnstake] = useState("");
  const { walletProvider } = useWeb3ModalProvider();

  const fetchBalances = useCallback(async () => {
    if (isConnected) {
      const multicall = getMulticallContract(walletProvider);
      const itf = new Interface(ZuckPepeABI);
      const itf_ = new Interface(ZuckABI);

      const masterchef = new Interface(masterchefABI);
      const smartchef = new Interface(SmartChefInitializableABI);

      try {
        const calls = [
          {
            target: contracts.ZuckPepe,
            callData: itf.encodeFunctionData("balanceOf", [address]),
          },
          {
            target: contracts.ZUCK,
            callData: itf_.encodeFunctionData("balanceOf", [address]),
          },
          {
            target: contracts.ZuckPepe,
            callData: itf.encodeFunctionData("balanceOf", [
              contracts.masterchef,
            ]),
          },
          {
            target: contracts.ZuckPepe,
            callData: itf.encodeFunctionData("balanceOf", [
              contracts.smartchefInitializeable,
            ]),
          },
          {
            target: contracts.masterchef,
            callData: masterchef.encodeFunctionData("pendingZuckPepe", [
              0,
              address,
            ]),
          },
          {
            target: contracts.smartchefInitializeable,
            callData: smartchef.encodeFunctionData("pendingReward", [address]),
          },
          {
            target: contracts.masterchef,
            callData: masterchef.encodeFunctionData("userInfo", [0, address]),
          },
          {
            target: contracts.smartchefInitializeable,
            callData: smartchef.encodeFunctionData("userInfo", [address]),
          },
        ];

        const { returnData } = await multicall.callStatic.aggregate(calls);
        const res = ethers.BigNumber.from(returnData[0]);
        const balance_zuckpepe = res.div(ethers.BigNumber.from(10).pow(18));

        const res_1 = ethers.BigNumber.from(returnData[1]);
        const balance_zuck = res_1.div(ethers.BigNumber.from(10).pow(18));

        const res_2 = ethers.BigNumber.from(returnData[2]);
        const balance_zuckpepe_masterchef = res_2.div(
          ethers.BigNumber.from(10).pow(18)
        );

        const res_3 = ethers.BigNumber.from(returnData[3]);
        const balance_zuckpepe_smartchef = res_3.div(
          ethers.BigNumber.from(10).pow(18)
        );

        const res_4 = ethers.BigNumber.from(returnData[4]);
        const _pendingZuckPepe = res_4.div(ethers.BigNumber.from(10).pow(18));

        const res_5 = ethers.BigNumber.from(returnData[5]);
        const _pendingZuck = res_5.div(ethers.BigNumber.from(10).pow(18));

        const decoded = masterchef.decodeFunctionResult(
          "userInfo",
          returnData[6]
        );
        const decoded_1 = smartchef.decodeFunctionResult(
          "userInfo",
          returnData[7]
        );

        const res_6 = ethers.BigNumber.from(decoded.amount);

        const _stakedAmountZuckPepe = res_6.div(
          ethers.BigNumber.from(10).pow(18)
        );

        const res_7 = ethers.BigNumber.from(decoded_1.amount);
        const _stakedAmountZuck = res_7.div(ethers.BigNumber.from(10).pow(18));

        setStakedAmountOfZuckPepe(parseInt(_stakedAmountZuckPepe.toString()));
        setStakedAmountOfZuck(parseInt(_stakedAmountZuck.toString()));

        setZuckPepeBalance(parseInt(balance_zuckpepe.toString()));
        setZuckBalance(parseInt(balance_zuck.toString()));
        setZuckPepeBalanceOfMasterChef(
          parseInt(balance_zuckpepe_masterchef.toString())
        );
        setZuckPepeBalanceOfSmartChef(
          parseInt(balance_zuckpepe_smartchef.toString())
        );
        setPendingZuckPepe(parseInt(_pendingZuckPepe.toString()));
        setPendingZuck(parseInt(_pendingZuck.toString()));
      } catch (error) {
        console.error(error);
      }
    }
  }, [address, isConnected, walletProvider]);

  useEffect(() => {
    fetchBalances();
  }, [address, fetchBalances]);

  const openStakeModal = (pool: string) => {
    setSelectedPool(pool);
    setIsModalOpen(true);
  };

  const openUnstakeModal = (pool: string) => {
    setSelectedPoolForUnstake(pool);
    setIsUnstakeModalOpen(true);
  };

  const checkTransactionStatus = async (
    transactionHash: string
  ): Promise<boolean> => {
    const receipt = await getProvider(walletProvider).getTransactionReceipt(
      transactionHash
    );
    if (receipt && receipt.status !== null) {
      // Check if the transaction was successful
      if (receipt.status === 1) {
        // Transaction successful
        return true;
      } else {
        // Transaction failed
        return false;
      }
    } else {
      // Transaction receipt not available yet, check again after a delay
      await new Promise((resolve) => setTimeout(resolve, 3000)); // Wait for 3 seconds
      return checkTransactionStatus(transactionHash); // Recursively check transaction status
    }
  };

  const onApprove = async (pool: string) => {
    try {
      const multicall = getMulticallContract(walletProvider);
      const itf = new Interface(ZuckPepeABI);
      const calls = [
        {
          target: contracts.ZuckPepe,
          callData: itf.encodeFunctionData("allowance", [
            address,
            contracts.masterchef,
          ]),
        },
        {
          target: contracts.ZuckPepe,
          callData: itf.encodeFunctionData("allowance", [
            address,
            contracts.smartchefInitializeable,
          ]),
        },
      ];

      const { returnData } = await multicall.callStatic.aggregate(calls);
      let check;
      if (pool === "ZuckPepe") {
        const res = ethers.BigNumber.from(returnData[0]);
        check = parseInt(res.div(ethers.BigNumber.from(10).pow(18)).toString());
        if (check >= parseInt(stakingAmount_1)) {
          return true; // Sufficient allowance, no need to approve
        }
      } else {
        const res = ethers.BigNumber.from(returnData[1]);
        check = parseInt(res.div(ethers.BigNumber.from(10).pow(18)).toString());
        if (check >= parseInt(stakingAmount_2)) {
          return true; // Sufficient allowance, no need to approve
        }
      }

      const signer = await getProvider(walletProvider).getSigner();
      let spender = "";
      let stakingAmount = ethers.constants.MaxUint256;
      if (pool === "ZuckPepe") {
        spender = contracts.masterchef;
      } else {
        spender = contracts.smartchefInitializeable;
      }

      const zuckPepeContract = new Contract(
        contracts.ZuckPepe,
        ZuckPepeABI,
        signer
      );
      setIsLoading(true);
      // Perform the approval transaction
      const transaction = await zuckPepeContract.approve(
        spender,
        stakingAmount
      );

      // Check the transaction status periodically
      return checkTransactionStatus(transaction.hash);
    } catch (error) {
      // Handle errors appropriately, such as displaying an error message to the user
      console.error(`Error approving tokens for ${pool}:`, error);
      setIsLoading(false);
      return false;
    }
  };

  const onStake = async (amount: number) => {
    const signer = await getProvider(walletProvider).getSigner();
    setIsLoading(true);
    const scaledAmount = ethers.utils.parseUnits(amount.toString(), 18);
    if (selectedPool === "ZuckPepe") {
      const masterchefContract = new Contract(
        contracts.masterchef,
        masterchefABI,
        signer
      );
      try {
        const transaction = await masterchefContract.enterStaking(scaledAmount);
        const result = await checkTransactionStatus(transaction.hash);
        if (result) {
          await fetchBalances();
          setIsLoading(false);
          setIsModalOpen(false); // Close modal
        } else {
          setIsLoading(false);
          return;
        }
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    } else {
      const smartchefInitializeableContract = new Contract(
        contracts.smartchefInitializeable,
        SmartChefInitializableABI,
        signer
      );
      try {
        const transaction = await smartchefInitializeableContract.deposit(
          scaledAmount
        );
        const result = await checkTransactionStatus(transaction.hash);

        if (result) {
          await fetchBalances();
          setIsLoading(false);
          setIsModalOpen(false);
        } else {
          setIsLoading(false);
          return;
        }
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    }
  };

  const handleStake = async () => {
    let result: boolean = false; // 변수의 타입을 명시적으로 설정
    let stakingAmount = 0;
    if (selectedPool === "ZuckPepe") {
      stakingAmount = parseFloat(stakingAmount_1);
      if (stakingAmount > zuckPepeBalance) {
        alert("Quantity you want to stake exceeds your balance");
        return;
      }
      result = (await onApprove(selectedPool)) || false; // Promise를 해결하여 값을 할당
    } else {
      stakingAmount = parseFloat(stakingAmount_2);
      if (stakingAmount > zuckPepeBalance) {
        alert("Quantity you want to stake exceeds your balance");
        return;
      }
      result = (await onApprove(selectedPool)) || false; // Promise를 해결하여 값을 할당
    }

    if (result) {
      // If approval successful, proceed with stake
      await onStake(stakingAmount);
    } else {
      // Handle case where approval failed
      // Optionally, display an error message to the user
    }

    return result; // Return the result as a Promise<boolean>
  };

  const handleUnstake = async () => {
    let unstakingAmount = 0;
    const signer = await getProvider(walletProvider).getSigner();
    if (selectedPoolforUnstake === "ZuckPepe") {
      unstakingAmount = parseFloat(unstakingAmount_1);
      if (unstakingAmount > stakedAmountOfZuckPepe) {
        alert("Quantity you want to unstake exceeds the amount you staked");
        return;
      }
      const scaledAmount = ethers.utils.parseUnits(
        unstakingAmount.toString(),
        18
      );
      try {
        setIsLoading(true);
        const masterchef = new Contract(
          contracts.masterchef,
          masterchefABI,
          signer
        );
        const transaction = await masterchef.leaveStaking(scaledAmount);
        const result = await checkTransactionStatus(transaction.hash);
        if (result) {
          await fetchBalances();
          setIsUnstakeModalOpen(false);
          setIsLoading(false);
          return;
        } else {
          setIsUnstakeModalOpen(false);
          setIsLoading(false);
          return;
        }
      } catch (error) {
        setIsUnstakeModalOpen(false);
        setIsLoading(false);
        console.error(error);
      }
    } else {
      unstakingAmount = parseFloat(unstakingAmount_2);

      if (unstakingAmount > stakedAmountOfZuck) {
        alert("Quantity you want to unstake exceeds the amount you staked");
        return;
      }
      const scaledAmount = ethers.utils.parseUnits(
        unstakingAmount.toString(),
        18
      );
      try {
        setIsLoading(true);
        const smartchef = new Contract(
          contracts.smartchefInitializeable,
          SmartChefInitializableABI,
          signer
        );
        const transaction = await smartchef.withdraw(scaledAmount);
        const result = await checkTransactionStatus(transaction.hash);
        if (result) {
          await fetchBalances();
          setIsLoading(false);
          setIsUnstakeModalOpen(false);
          return;
        } else {
          setIsLoading(false);
          setIsUnstakeModalOpen(false);
          return;
        }
      } catch (error) {
        setIsLoading(false);
        setIsUnstakeModalOpen(false);
        console.error(error);
      }
    }
  };

  const onClaim = async (pool: string) => {
    const signer = await getProvider(walletProvider).getSigner();
    const scaledAmount = ethers.utils.parseUnits("0", 18);
    if (pool === "ZuckPepe") {
      const masterchefContract = new Contract(
        contracts.masterchef,
        masterchefABI,
        signer
      );
      try {
        const transaction = await masterchefContract.leaveStaking(scaledAmount);
        const result = await checkTransactionStatus(transaction.hash);

        if (result) {
          await fetchBalances();
          return;
        } else {
          return;
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      const smartchefContract = new Contract(
        contracts.smartchefInitializeable,
        SmartChefInitializableABI,
        signer
      );

      try {
        const transaction = await smartchefContract.withdraw(scaledAmount);
        const result = await checkTransactionStatus(transaction.hash);

        if (result) {
          await fetchBalances();
          return;
        } else {
          return;
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleBalanceClick = () => {
    selectedPool === "ZuckPepe"
      ? setStakingAmount1(zuckPepeBalance.toString())
      : setStakingAmount2(zuckPepeBalance.toString());
  };

  const handleUnstakeBalanceClick = () => {
    selectedPoolforUnstake === "ZuckPepe"
      ? setUnstakingAmount1(stakedAmountOfZuckPepe.toString())
      : setUnstakingAmount2(stakedAmountOfZuck.toString());
  };

  return (
    <div className="Staking-Home">
      {address ? (
        <div className="container">
          <div className="balance">
            <span className="title">Your Wallet Balances</span>
            {address ? (
              <div className="balance-container">
                <div className="details-table">
                  <p>Zuck Balance:</p> <p>{zuckBalance.toLocaleString()}</p>{" "}
                </div>
                <div className="details-table">
                  <p>ZuckPepe Balance:</p>
                  <p> {zuckPepeBalance.toLocaleString()}</p>
                </div>
              </div>
            ) : (
              <div className="balance-container">
                <p>ZuckPepe Balance: </p>{" "}
                <Skeleton variant="rectangular" width={200} height={25} />
                <p>Zuck Balance: </p>{" "}
                <Skeleton variant="rectangular" width={200} height={25} />
              </div>
            )}
          </div>
          <div className="staking-container">
            <div className="staking-items">
              <div className="pair-container">
                <img
                  src="/images/home-pepe.svg"
                  alt="zuck-pepe"
                  className="token-img-1"
                />
                <img
                  src="/images/autorenew.svg"
                  alt="zuck-pepe"
                  className="token-img-2"
                />
                <p className="pool-name">Stake ZuckPepe</p>
              </div>
              {address ? (
                <div className="description">
                  <div className="details-table">
                    <p>Total Staked: </p>
                    <p>
                      {zuckPepeBalance_masterchef.toLocaleString()} ZuckPepe
                    </p>
                  </div>
                  {stakedAmountOfZuckPepe > 0 && (
                    <div className="details-table">
                      <p>You Staked :</p>
                      <p>{stakedAmountOfZuckPepe.toLocaleString()} ZuckPepe</p>
                    </div>
                  )}
                  {pendingZuckPepe > 0 ? (
                    <div className="details-table">
                      <p>You Earned :</p>
                      <p>{pendingZuckPepe.toLocaleString()} ZuckPepe</p>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <div className="description">
                  <p>Total Staked:</p>
                  <Skeleton variant="rectangular" width={200} height={25} />
                </div>
              )}
              <div className="button-container">
                <Button
                  onClick={() => openStakeModal("ZuckPepe")}
                  variant="contained"
                >
                  Stake ZuckPepe
                </Button>
                {pendingZuckPepe > 0 && (
                  <Button
                    variant="contained"
                    onClick={() => onClaim("ZuckPepe")}
                  >
                    Claim ZuckPepe
                  </Button>
                )}
                {stakedAmountOfZuckPepe > 0 && (
                  <Button
                    variant="contained"
                    onClick={() => openUnstakeModal("ZuckPepe")}
                  >
                    Unstake ZuckPepe
                  </Button>
                )}
              </div>
            </div>
            <div className="staking-items">
              <div className="pair-container">
                <img
                  src="/images/zuck-logo.png"
                  alt="zuck-pepe"
                  className="token-img-1"
                />
                <img
                  src="/images/home-pepe.svg"
                  alt="zuck-pepe"
                  className="token-img-2"
                />
                <div>
                  <p className="pool-name">Earn Zuck</p>
                  <p className="pool-details">Stake ZuckPepe</p>
                </div>
              </div>
              {address ? (
                <div className="description">
                  <div className="details-table">
                    <p>Total Staked:</p>
                    <p>{zuckPepeBalance_smartchef.toLocaleString()} ZuckPepe</p>
                  </div>

                  {stakedAmountOfZuck > 0 && (
                    <div className="details-table">
                      <p>You Staked :</p>
                      <p>{stakedAmountOfZuck.toLocaleString()} ZuckPepe</p>
                    </div>
                  )}
                  {pendingZuck > 0 && (
                    <div className="details-table">
                      <p>You Earned :</p>
                      <p>{pendingZuck.toLocaleString()} Zuck</p>
                    </div>
                  )}
                </div>
              ) : (
                <div className="description">
                  <p>Total Staked:</p>
                  <Skeleton variant="rectangular" width={200} height={25} />
                </div>
              )}
              <div className="button-container">
                <Button
                  onClick={() => openStakeModal("ZUCK")}
                  variant="contained"
                >
                  Stake ZuckPepe
                </Button>
                {pendingZuck > 0 && (
                  <Button variant="contained" onClick={() => onClaim("ZUCK")}>
                    Claim ZUCK
                  </Button>
                )}
                {stakedAmountOfZuck > 0 && (
                  <Button
                    variant="contained"
                    onClick={() => openUnstakeModal("ZUCK")}
                  >
                    Unstake ZuckPepe
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            margin: "3rem",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span>Connect your wallet first</span>
          <ConnectWallet />
        </div>
      )}

      <Footer />

      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="modal">
          <div className="modal-content">
            <h2 id="modal-title">Stake ZuckPepe</h2>

            <TextField
              type="number"
              value={
                selectedPool === "ZuckPepe" ? stakingAmount_1 : stakingAmount_2
              }
              onChange={(e) =>
                selectedPool === "ZuckPepe"
                  ? setStakingAmount1(e.target.value)
                  : setStakingAmount2(e.target.value)
              }
              label="Enter amount of ZuckPepe"
              variant="outlined"
              InputProps={{
                inputProps: {
                  min: 0,
                  max: zuckPepeBalance,
                },
              }}
            />
            <p className="balance-p" onClick={handleBalanceClick}>
              Balance: {zuckPepeBalance.toLocaleString()}
            </p>

            <Button
              onClick={handleStake}
              variant="contained"
              disabled={isloading}
            >
              {isloading ? (
                <CircularProgress size={20} />
              ) : (
                <span>Confirm</span>
              )}
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        open={isUnstakeModalOpen}
        onClose={() => setIsUnstakeModalOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="modal">
          <div className="modal-content">
            <h2 id="modal-title">Unstake ZuckPepe</h2>

            <TextField
              type="number"
              value={
                selectedPoolforUnstake === "ZuckPepe"
                  ? unstakingAmount_1
                  : unstakingAmount_2
              }
              onChange={(e) =>
                selectedPoolforUnstake === "ZuckPepe"
                  ? setUnstakingAmount1(e.target.value)
                  : setUnstakingAmount2(e.target.value)
              }
              label="Enter amount of ZuckPepe"
              variant="outlined"
              InputProps={{
                inputProps: {
                  min: 0,
                  max:
                    selectedPoolforUnstake === "ZuckPepe"
                      ? stakedAmountOfZuckPepe
                      : stakedAmountOfZuck,
                },
              }}
            />
            {selectedPoolforUnstake === "ZuckPepe" ? (
              <p className="balance-p" onClick={handleUnstakeBalanceClick}>
                You Staked: {stakedAmountOfZuckPepe.toLocaleString()}
              </p>
            ) : (
              <p className="balance-p" onClick={handleUnstakeBalanceClick}>
                You Staked: {stakedAmountOfZuck.toLocaleString()}
              </p>
            )}

            <Button
              onClick={handleUnstake}
              variant="contained"
              disabled={isloading}
            >
              {isloading ? (
                <CircularProgress size={20} />
              ) : (
                <span>Confirm</span>
              )}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
