import React from "react";
import "./Tokenomics.css";

type TokenomicsProps = {
    id: string;
};

export default function Tokenomics({ id }: TokenomicsProps) {
    return (
        <div className="Tokenomics" id={id}>
            <div className="Main-images Tokenomics-image">
                <img src="/images/Tokenomics-pepe.svg" alt="tokenomics-pepe" className="Main-pepes Tokenomics-pepe" />
            </div>
            <div className="Tokenomics-container">
                <h1 className="Tokenomics-title">TOKENOMICS</h1>
                <h1 className="Tokenomics-subtitle">TOKENOMICS OF ZUCKPEPE</h1>
                <div className="Tokenomics-divider"></div>
                <h1 className="Tokenomics-content">
                    Contract Address<br /> 0x83a4DEa0a138894F2783203380683aBa09Caf8C1<br /><br />
                    Total Supply<br /> 420,690,000,000,000<br /><br />
                    No Taxes<br /><br />
                    Security<br /> Ownership Renounced<br />
                </h1>
            </div>
        </div>
    );
}
