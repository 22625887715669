import React, { useState } from "react";
import { Menu, MenuItem, Button } from "@mui/material";
import {
  useWeb3ModalAccount,
  useWeb3Modal,
  useDisconnect,
} from "@web3modal/ethers5/react";

export default function ConnectWallet() {
  const { address, isConnected } = useWeb3ModalAccount();
  const [showCopiedTooltip, setShowCopiedTooltip] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { open } = useWeb3Modal();
  const { disconnect } = useDisconnect();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function shortenAddress(
    address: string,
    firstCharsCount: number = 6,
    lastCharsCount: number = 4
  ): string {
    if (!address) return "";
    return `${address.substring(0, firstCharsCount)}...${address.substring(
      address.length - lastCharsCount
    )}`;
  }

  const handleCopyAddress = () => {
    if (isConnected) {
      navigator.clipboard
        .writeText(address as string)
        .then(() => {
          setShowCopiedTooltip(true);
          setTimeout(() => {
            setShowCopiedTooltip(false);
          }, 2000);
        })
        .catch((error) => {
          console.error("Failed to copy wallet address: ", error);
        });
    }
    handleClose();
  };

  return (
    <div className="Header-navbar-end">
      {isConnected ? (
        <>
          <Button
            aria-controls="wallet-menu"
            aria-haspopup="true"
            onClick={handleClick}
            sx={{
              display: "flex",
              alignItems: "center",
              color: "black",
              fontSize: "1rem",
              bgcolor: "#f0b829",
              padding: "1rem",
              borderRadius: "0.5rem",
              marginRight: "0.5rem",
              "&:hover": {
                bgcolor: "#e0a020", // 호버 시 배경색 변경
              },
            }}
          >
            {showCopiedTooltip ? "Copied" : shortenAddress(address as string)}
          </Button>
          <Menu
            id="wallet-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem onClick={handleCopyAddress}>Copy Address</MenuItem>
            <MenuItem onClick={disconnect}>Disconnect Wallet</MenuItem>
          </Menu>
        </>
      ) : (
        <button className="Header-navbar-btn" onClick={() => open()}>
          Connect Wallet
        </button>
      )}
    </div>
  );
}
