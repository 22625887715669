// WalletContext.js

import React, { createContext, useContext, useState } from "react";

export const WalletContext = createContext({
  walletAddress: null,
  setWalletAddress: (string) => {}, // 기본적으로 빈 함수를 전달
});

export const useWallet = () => {
  return useContext(WalletContext);
};

export const WalletProvider = ({ children }) => {
  const [walletAddress, setWalletAddress] = useState("");

  const handleSetWalletAddress = (address) => {
    setWalletAddress(address);
  };

  return (
    <WalletContext.Provider
      value={{ walletAddress, setWalletAddress: handleSetWalletAddress }}
    >
      {children}
    </WalletContext.Provider>
  );
};
