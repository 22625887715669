import { Contract } from "@ethersproject/contracts";
import { ethers } from "ethers";
import multicallAbi from "./Multicall.json";

export const getMulticallContract = (walletProvider: any) => {
  const provider = new ethers.providers.Web3Provider(walletProvider);
  return new Contract(
    "0xcA11bde05977b3631167028862bE2a173976CA11",
    multicallAbi,
    provider
  );
};
