import React from "react";
import "./Contact.css";

export default function Contact() {
    return (
        <div className="Contact">
            <h1 className="Contact-title">contact</h1>
            <a className="Contact-start" href=" " target="_blank" rel="noreferrer">
                <img src="/images/header-icon.svg" alt="logo" className="Contact-start-icon" />
                <span>ZuckPepe</span>
            </a>
            <div className="Contact-iconbtns">
                <a href="https://twitter.com/ZuckPepe" target="_blank" rel="noreferrer">
                    <img src="/images/Contact-twitter.svg" alt="twitter" />
                </a>
                <a href="https://t.me/zuckpepe" target="_blank" rel="noreferrer">
                    <img src="/images/Contact-telegram.svg" alt="etherscan" />
                </a>
            </div>
            <h1 className="Contact-content">
                $ZuckPepe coin has no association with Matt Furie or his creation Pepe the Frog.
                <br /> This token is simply paying homage to a meme we all love and recognize.
            </h1>
        </div>
    );
}
