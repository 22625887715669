import React from "react";
import "./About.css";

type AboutProps = {
    id: string;
};

export default function About({ id }: AboutProps) {
    return (
        <div className="About" id={id}>
            <div className="About-container">
                <h1 className="About-title">about</h1>
                <div className="About-divider"></div>
                <h1 className="About-content">
                    Elon Musk meme is boring.
                    In the future, Mark Zuckerberg Pepe will lead Memecoin.
                    ZuckPepe to the Moon
                </h1>
            </div>
            <div className="Main-images About-image">
                <img src="/images/about-pepe.svg" alt="about-pepe" className="Main-pepes About-pepe" />
            </div>
        </div>
    );
}
