import React from "react";
import "./Footer.css";

export default function Footer() {
  return (
    <div className="Home-footer">
      <div className="Home-iconbtns">
        <a href="https://twitter.com/ZuckPepe" target="_blank" rel="noreferrer">
          <img src="/images/home-twitter.svg" alt="twitter" />
        </a>
        <a
          href="https://www.dextools.io/app/en/ether/pair-explorer/0xc2aa8b8c005c0c42e36740699599e14199dd0095"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/images/home-dextools.svg" alt="dextools" />
        </a>
        <a
          href="https://etherscan.io/address/0x83a4DEa0a138894F2783203380683aBa09Caf8C1"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/images/home-etherscan.svg" alt="etherscan" />
        </a>
      </div>
    </div>
  );
}
