import React, { useState, useEffect } from "react";
import "./StakingHeader.css";
import { HashLink } from "react-router-hash-link";
import ConnectWallet from "../ConnectWallet/ConnectWallet";

const navItems = [
  { name: "HOME", path: "/" },
  { name: "STAKING", path: "/staking" },
];

export default function StakingHeader() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <header className="Header">
      <HashLink smooth to="/" className="Header-navbar-start">
        <img
          src="/images/header-icon.svg"
          alt="logo"
          className="Header-navbar-start-icon"
        />
        {windowWidth > 600 && <span>ZuckPepe</span>}
        <div className="Header-navbar-Link">
          {navItems.map((item, index) => (
            <HashLink
              key={index}
              smooth
              to={item.path}
              className="Header-navbar-item"
            >
              {item.name}
            </HashLink>
          ))}
        </div>
      </HashLink>
      <ConnectWallet />
    </header>
  );
}
