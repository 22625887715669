import React from "react";
import "./Home.css";
const btnItems = [
  {
    name: "BUY ZuckPepe",
    path: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x83a4dea0a138894f2783203380683aba09caf8c1",
  },
  {
    name: "DEXTOOLS",
    path: "https://www.dextools.io/app/en/ether/pair-explorer/0xc2aa8b8c005c0c42e36740699599e14199dd0095",
  },
  {
    name: "CONTRACT",
    path: "https://etherscan.io/address/0x83a4DEa0a138894F2783203380683aBa09Caf8C1",
  },
];

type HomeProps = {
  id: string;
};

export default function Home({ id }: HomeProps) {
  return (
    <div className="Home" id={id}>
      <div className="Main-images Home-image">
        <img
          src="/images/home-pepe.svg"
          alt="home-pepe"
          className="Main-pepes Home-pepe"
        />
      </div>
      <div className="Home-container">
        <h1 style={{ fontSize: "2rem", fontWeight: 500, margin: 0 }}>
          Welcome To
        </h1>
        <h1
          id="second-heading"
          className="second-heading"
          style={{ fontSize: "7.2rem", fontWeight: 900, margin: 0 }}
        >
          ZuckPepe
        </h1>
        <h1 style={{ fontSize: "1.6rem", fontWeight: 400, margin: 0 }}>
          Missed $PEPE?
        </h1>
        <h1 style={{ fontSize: "1.6rem", fontWeight: 400, margin: 0 }}>
          Here is your second chance
        </h1>
        <div className="Home-divider"></div>
        <div className="Home-btns">
          {btnItems.map((item, index) => (
            <span className="Home-btn" key={index}>
              <a href={item.path} target="_blank" rel="noreferrer">
                {item.name}{" "}
              </a>
            </span>
          ))}
        </div>
        <div className="Home-iconbtns">
          <a
            href="https://twitter.com/ZuckPepe"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/images/home-twitter.svg" alt="twitter" />
          </a>
          <a
            href="https://www.dextools.io/app/en/ether/pair-explorer/0xc2aa8b8c005c0c42e36740699599e14199dd0095"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/images/home-dextools.svg" alt="dextools" />
          </a>
          <a
            href="https://etherscan.io/address/0x83a4DEa0a138894F2783203380683aBa09Caf8C1"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/images/home-etherscan.svg" alt="etherscan" />
          </a>
        </div>
        <h1
          style={{ fontSize: "1.6rem", fontWeight: 500, margin: "0 0 2rem 0" }}
        >
          Contract
        </h1>
        <h1 style={{ fontSize: "1.6rem", fontWeight: 500, margin: 0 }}>
          0x83a4DEa0a138894F2783203380683aBa09Caf8C1
        </h1>
      </div>
    </div>
  );
}
