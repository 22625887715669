import React from "react";
import Header from "../../components/Header/Header";
import Home from "../../components/Home/Home";
import About from "../../components/About/About";
import Tokenomics from "../../components/Tokenomics/Tokenomics";
import Contact from "../../components/Contact/Contact";
import "./Main.css";

function Main() {
    return (
        <div className="Main">
            <Header />
            <Home id="home" />
            <About id="about" />
            <Tokenomics id="tokenomics" />
            <Contact />
        </div>
    );
}

export default Main;
